<template>
  <travio-center-container grid-width='2/3'>
    <h2 style="color:#636363" class="mb-4">Extra Group List</h2>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button @click="addExtra" class="mr-4 sm:mb-0 mb-2">
            Add
          </vs-button>
          <vs-input
            class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Search..."
          />
        </div>
      </div>
       <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="extraGroupList"
        :rowDragManaged="true"
        :animateRows="true"
        :gridOptions="gridOptions"
        :autoGroupColumnDef="autoGroupColumnDef"
        rowSelection="single"
        :pagination="false"
        :context="context"
        :suppressPaginationPanel="true"
      >
      </ag-grid-vue>
      
      
    </vx-card>
  
  </travio-center-container>
</template>

<script>
    
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';
import CellRendererActiveCheckbox from './cell-renderers/CellRendererActiveCheckbox.vue'
import CellRendererGroupActions from './cell-renderers/CellRendererGroupActions.vue'

export default {
  components: {
    AgGridVue,
    CellRendererActiveCheckbox,
    CellRendererGroupActions
  },
  props: {
    applicationId: { required: true}
  },
  data () {
    return {
      extraGroupList: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererActiveCheckbox,
        CellRendererGroupActions
      },
      context: null,
      searchQuery: ''
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    if (!this.appHasFeature('customextras')) {
      this.$router.push('/error-404')
    }
    // this.extraGroupList = [
    //   { id: 1, groupName: 'Extra 1', extraCount: 4, active: true },
    //   { id: 1, groupName: 'Extra 2', extraCount: 9, active: true },
    // ]
    this.$vs.loading()

    try {
      const response = await this.$http.get(`api/customextras/apps/${this.applicationId}/groups`)
      this.extraGroupList = response.data.customExtras

    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close();

  },
  beforeMount() {

    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'id', hide: true },
      { headerName: 'Group Name', field: 'groupName', rowDrag: true, width: 280,  suppressSizeToFit: false },
      { headerName: 'Extras', field: 'extraCount', width: 120,  suppressSizeToFit: false },
      { headerName: 'Active', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActiveCheckbox) },
      { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererGroupActions) },
      { field: 'orderId', hide: true },
      { field: 'isActive', hide: true },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 60,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }

    this.autoGroupColumnDef = { minWidth: 200 };
    
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();

    this.gridOptions.onRowDragEnd = async (e) => { 
      //https://www.ag-grid.com/vue-grid/row-dragging/#row-drag-events

      const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      }

      const rowNodeGettingDragged = e.node
      const fromIndex = this.$_.findIndex(this.extraGroupList, x => x.id == rowNodeGettingDragged.data.id)
      const toIndex = e.overIndex
      array_move(this.extraGroupList, fromIndex, toIndex)
      
      this.extraGroupList = JSON.parse(JSON.stringify(this.extraGroupList))

      try {
        let response = await this.$http.put(
          `api/customextras/apps/${this.applicationId}/groups/reorder`,
          { groupOrderItems: this.extraGroupList })

        this.$_notifySuccess('Successfully reordered groups');
      }
      catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()

    }
  },
  methods: {
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    addExtra () {
      // alert('add')
      this.$router.push({name: 'application-tools-extra-groups-add', 
        params: { applicationId: this.applicationId }
      })
    },
    onManageExtra (groupId) {
      this.$router.push({name: 'application-tools-extra-groups-extra-list', 
        params: { 
          applicationId: this.applicationId,
          groupId: groupId 
        }
      })
    },
    onEditExtra (groupId) {
      this.$router.push({name: 'application-tools-extra-groups-edit', 
        params: { 
          applicationId: this.applicationId,
          groupId: groupId 
        }
      })
    },
  }
}
</script>

<style>

</style>