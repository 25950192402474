<template>
  <div>
    <feather-icon title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onManageExtra" />
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect
  },
  data () {
    return {
      showDeletePopup: false
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    onManageExtra () {
      this.params.context.componentParent.onManageExtra(this.params.data.id) 
    },
  }
}
</script>
